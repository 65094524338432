import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Container from '../components/Container'
import * as contact from '../components/Contact/index'
import Icon from '../utils/Icon'
import styled from 'styled-components'

const IconWraperStyled = styled(Icon)`
  height: 36px;
  width: 36px;
  fill: #2e2529;
`

class Kontakt extends React.Component {
  render() {
    const siteTitle = 'Kontakt z nami'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} keywords={[`kontakt`]} />
        <section className="section is-midle">
          <Container>
            <div className="columns">
              <div className="column">
                <h1 className="title">{siteTitle}</h1>
                <br />
                <article className="media">
                  <figure className="media-left">
                    <IconWraperStyled name="phone" />
                  </figure>
                  <div className="media-content">
                    <div className="content">
                      <p>
                        <strong>Telefon:</strong>
                        <br />
                        {contact.mobilePhoneNumber}
                        <br />
                        {contact.mobilePhoneAlarmNumber}
                        <br />
                        {contact.staticPhoneNumber}
                      </p>
                    </div>
                  </div>
                </article>
                <article className="media">
                  <figure className="media-left">
                    <IconWraperStyled name="mail" />
                  </figure>
                  <div className="media-content">
                    <div className="content">
                      <p>
                        <strong>Email:</strong>
                        <br />
                        {contact.email}
                      </p>
                    </div>
                  </div>
                </article>
                <article className="media">
                  <figure className="media-left">
                    <IconWraperStyled name="clock" />
                  </figure>
                  <div className="media-content">
                    <div className="content">
                      <p>
                        <strong>Otwarte:</strong>
                        <ul>
                          {contact.isOpen.workingDay.map(item => (
                            <li>{item}</li>
                          ))}
                        </ul>
                      </p>
                    </div>
                  </div>
                </article>
                <article className="media">
                  <figure className="media-left">
                    <IconWraperStyled name="map-marker" />
                  </figure>
                  <div className="media-content">
                    <div className="content">
                      <p>
                        <strong>Adres:</strong>
                        <br />
                        {contact.currentAdress.street}
                        <br />
                        {contact.currentAdress.code}
                      </p>
                    </div>
                  </div>
                </article>
                <article className="media">
                  <figure className="media-left">
                    <IconWraperStyled name="map-marker" />
                  </figure>
                  <div className="media-content">
                    <div className="content">
                      <p>
                        <strong>Adres:</strong>
                        <br />
                        {contact.secondAdress.street}
                        <br />
                        {contact.secondAdress.code}
                      </p>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </Container>
        </section>
      </Layout>
    )
  }
}

export default Kontakt
